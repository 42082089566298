
import './App.css';
import PhotoFolders from './Components/PhotoFolders';

function App() {
  return (
    <div>
      <PhotoFolders/>
   
    </div>
  );
}

export default App;
