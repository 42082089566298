// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

// Allan test config
/* const firebaseConfig = {
  apiKey: "AIzaSyA3tMgaAk6U7qBfVHp6qrOrTGAKImPcwnM",
  authDomain: "auction-site-74fd5.firebaseapp.com",
  projectId: "auction-site-74fd5",
  storageBucket: "auction-site-74fd5.appspot.com",
  messagingSenderId: "1032945886949",
  appId: "1:1032945886949:web:074e7156a3e2722c1e960c"
}; */

// Client Config
const firebaseConfig = {
  apiKey: 'AIzaSyDLvCT23FhbNhVbKpu7r7I2b0vdhGgNbIA',
  authDomain: 'test-8baa1.firebaseapp.com',
  projectId: 'test-8baa1',
  storageBucket: 'test-8baa1.appspot.com',
  messagingSenderId: '443570294513',
  appId: '1:443570294513:web:13b75233ccf599b5f0a350',
  measurementId: 'G-TM95H847L3',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, storage, auth };
